import axios from 'axios';
import { Notify } from 'vant';

const httpConfig = {
  timeout: 30000, // 超时自动取消请求
  responseType: 'json', // 返回数据格式
  headers: { 'Content-Type': 'application/json;charset=utf-8;' },
  notice: false, // 是否弹出成功提示
  process: true, // 是否在拦截函数中处理数据
};

// 请求拦截
const requestInterceptors = [
  (config) => {
    // 统一修改请求地址参数
    config.headers['http-token'] = process.env.VUE_APP_HTTP_TOKEN;
    config.headers['lan-code'] = 'en-US';
    return config;
  },
  (error) => Promise.reject(error),
];

// 响应拦截
const responseInterceptors = [
  (response) => {
    // 处理响应数据
    const result = response.data;
    const responseMsg = result.message || `Error: ${result.code}`;
    if (response.config && !response.config.process) {
      return result;
    }
    if (result.code !== 1) {
      // 接口错误码判断
      Notify({
        type: 'danger',
        message: responseMsg,
      });
      return false;
    }
    if (response.config && response.config.notice) {
      Notify({ type: 'success', message: responseMsg });
    }
    return result.data ? result.data : true;
  },
  (error) => {
    // http错误码判断
    let responseMsg = 'Network error, please check';
    if (error.response) {
      if (error.response.status >= 500) {
        responseMsg = 'The server is busy, please try again later';
      }
      if (error.response.status >= 400) {
        responseMsg = 'Client error, please try again later';
      }
    }
    Notify({ type: 'danger', message: responseMsg });
    // 返回 response 里的错误信息
    return Promise.reject(error);
  },
];

export const $http = axios.create(
  Object.assign(
    { baseURL: process.env.VUE_APP_API_PREFIX + process.env.VUE_APP_API_URL },
    httpConfig
  )
);
$http.interceptors.request.use(...requestInterceptors);
$http.interceptors.response.use(...responseInterceptors);

export default $http;
